<template>
  <div class="assinatura">
    <Voltar />
    <h1 class="titulo">Seu Pagamento foi realizado com sucesso!</h1>
    <p>
      Agora você já pode participar de enquetes exclusivas, assistir ao vivo a
      programação da RedeTV! de qualquer local do mundo e concorrer aos prêmios
      diários.
    </p>
    <div class="bt bt--destaque">
      <a @click="() => router.push({ name: 'Premios' })">Conheça nossos Prêmios</a>
    </div>
    <img :src="state.promocao.imagem" :alt="state.promocao.titulo" />
    <div class="bt">
      <a @click="() => router.push({ name: 'Regulamento' })">Regulamento</a>
    </div>
  </div>
</template>
<script>
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import services from '../../services'
export default {
  setup () {
    const router = useRouter()
    const toast = useToast()
    let user = JSON.parse(window.localStorage.getItem('user'))

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      promocao: {}
    })

    async function getPromocao () {
      try {
        state.isLoading = true
        const { data, errors } = await services.promocao.index()

        if (!errors) {
          state.promocao = data
          state.isLoading = false
          return
        }

        if (errors.status === 404 || errors.status === 401) {
          toast.error('Promoção não encontrada')
        }

        if (errors.status === 422) {
          toast.error(errors.statusText)
        }
        state.isLoading = false
      } catch (error) {
        toast.error('Erro ao fazer carregar a Promoção!')
        state.isLoading = false
        state.hasErrors = !!error
      }
    }
    getPromocao()

    async function getUsuario () {
      try {
        state.isLoading = true
        const { data, errors } = await services.cadastro.usuario({
          token: user.token
        })

        if (!errors) {
          window.localStorage.setItem('user', JSON.stringify(data))
          user = data
          state.isLoading = false
          return
        }

        if (errors.status === 404 || errors.status === 401) {
          toast.error('Usuario não Encontrado')
        }

        if (errors.status === 422) {
          toast.error(errors.statusText)
        }
        state.isLoading = false
      } catch (error) {
        toast.error('1 - Usuario não Encontrado')
        state.isLoading = false
        state.hasErrors = !!error
      }
    }
    getUsuario()

    return {
      router,
      state
    }
  }
}
</script>
